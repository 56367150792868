<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div v-if="list && list.length > 0">
      <div v-for="(item,index) in list" :key="index" class="main_box">
        <div class="top_box">{{ item.create_time }}</div>
        <div class="content_box">
          <div class="image">
            <img :src="item.img" alt="">
          </div>
          <div class="content">
            <div class="content_top">
              <p class="title">{{ item.gift_name }}</p>
              <p class="coin">{{ item.spent_zlb }}筑龙币</p>
            </div>
            <div class="num">x{{ item.num }}</div>
          </div>
        </div>
        <div class="bottom">
          <button @click="seeLogistics(item)">查看物流</button>
        </div>
      </div>
    </div>
    <div v-else class="nodatas">
      <img src="@/static/images/my_index/kong.png" alt="kong">
      <p>您还没有兑换过礼品哦~</p>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { ExchangeRecordList } from '@/api/my_user'
export default {
  components: {
    heads
  },
  data () {
    return {
      title_msg: '兑换记录',
      list: []
    }
  },
  mounted () {
    this.getMsg()
  },
  methods: {
    // 兑换记录列表
    getMsg () {
      var params = {
        page: 1,
        limit: 500
      }
      ExchangeRecordList(params).then(res => {
        this.list = res.result.list ? res.result.list : []
        console.log(this.list)
      })
    },
    // 查看物流
    seeLogistics (item) {
      this.$router.push({
        path: '/GiftExpress_detail',
        query: {
          // id: item.address_id
          id: item.id
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave (to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.main_box {
  width: 100%;
  height: 310px;
  margin-top: 20px;
  background-color: #fff;
}
.top_box {
  color: #999999;
  // font-size: 22px;
  font-size: 24px;
  // font-weight: 400;
  padding: 30px 30px;
}
.content_box {
  height: 150px;
  display: flex;
  justify-content: start;
}
.image {
  width: 150px;
  height: 150px;
  margin-left: 30px;
  img {
    width: 100%;
    height: 100%;
    // border-radius: 14px;
    border-radius: 10px;
    background: antiquewhite;
  }
}
.content {
  width: calc(100% - 180px);
}
.content_top {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 30px 0 0 30px;
}
.title {
  width: calc(100% - 230px);
  // font-size: 26px;
  font-size: 30px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.coin {
  width: 200px;
  margin-right: 30px;
  color: #ee2e2f;
  // font-size: 26px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: right;
}
.num {
  margin: 20px 30px 0 30px;
  text-align: right;
  color: #999999;
}
.bottom {
  height: 68px;
  display: flex;
  flex-direction: row-reverse;
  button {
    width: 150px;
    height: 58px;
    border: 1px solid #cccccc;
    text-align: center;
    // border-radius: 4px;
    border-radius: 29px;
    color: #333333;
    margin-right: 30px;
    background: #fff;
    font-size: 26px;
    font-weight: 500;
  }
}
.nodatas {
  margin-top: 250px;
  text-align: center;
  p {
    color: #333;
    font-size: 26px;
    margin-top: 20px;
  }
}
</style>
